import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import ModalLogin from './Login/modal-login';
import { ModalForm, ModalState } from './modal';
import { GrayTile } from './tile/tile';
import { Tooltip } from './tooltip';

export const Comparative = ({comparative, modals}) => {
	return (
		<>
			<ComparativeMobile comparative={comparative} />
			<ComparativeDesktop comparative={comparative} />
			{modals}
		</>
	)
}

const ComparativeMobile = ({comparative}) => {
	return <div className='table-mobile show-sm'>
		{comparative.map(item => {
			return <div className='table-mobile-block'>
				<ComparativeMobileHeader comparative={item} />
				<ComparativeMobileBody characteristicsList={item.characteristicsList} />
			</div>
		})}
	</div>
}

const ComparativeMobileHeader = ({comparative}) => {
	return <GrayTile thin>
		{typeof comparative.name === 'string' ? <h3>{comparative.name}</h3> : <GatsbyImage image={getImage(comparative.name.localFile)} alt={comparative.name.alternativeText} />}
		{comparative.buttons.map(button => button)}
	</GrayTile>
}

const ComparativeMobileBody = ({characteristicsList}) => {
	const title = characteristicsList.title
	const characteristics = characteristicsList.characteristics
	// TODO
	return <div className='table-mobile-block__list'>
		{title && <h5>{title}</h5>}
		{characteristics.map(characteristic => 
			<div className='table-mobile-block__list__item'>
				<IconComponent bool={characteristic.bool} text={characteristic.text} onlyIcon />
				{/* {getIconText(characteristic.text, characteristic.bool)} */}
				<span>{characteristic.name}{characteristic.text ? `: ${characteristic.text}` : ''}</span>
			</div>
		)}
	</div>
}

const ComparativeDesktop = ({comparative}) => {
	return <>
		<table className='table table--thin hidden-sm'>
			<ComparativeDesktopHeader comparative={comparative} />
			<ComparativeDesktopBody comparative={comparative} />
		</table>
	</>
}

const ComparativeDesktopHeader = ({comparative}) => {
	return <thead>
		<tr>
			<th>
				<div className='table__cell' />
			</th>
			{comparative.map(item => {
				return <th>
					<div className={`table__cell ${item.highlighted ? 'table__cell__highlight--top' : ''}`}>
						{typeof item.name === 'string' ? <h5>{item.name}</h5>: <GatsbyImage image={getImage(item.name.localFile)} alt={item.name.alternativeText} />}
					</div>
				</th>
			})}
		</tr>
	</thead>
}

const ComparativeDesktopBody = ({comparative}) => {
	const characteristics = comparative[0].characteristicsList.characteristics
	
	return <tbody>
		{characteristics.map((characteristic, characteristicIndex) => 
			<tr>
				<td>
					<div className='table__cell'>
						<b>{characteristic.name}</b>
					</div>
				</td>
				<td>
					<div className={`table__cell ${comparative[0].highlighted ? 'table__cell__highlight--x': ''}`}>
						<IconComponent bool={characteristic.bool} text={characteristic.text} />
					</div>
				</td>
				{comparative.map((row, index) => {
					if (index !== 0) {
						const characteristic = row.characteristicsList.characteristics[characteristicIndex]
						return <td>
							<div className={`table__cell ${comparative[index].highlighted ? 'table__cell__highlight--x': ''}`}>
								<IconComponent bool={characteristic.bool} text={characteristic.text} />
							</div>
						</td>
					}
				})}
			</tr>
		)}
		<ComparativeDesktopFooter comparative={comparative} />
	</tbody>
}

const ComparativeDesktopFooter = ({comparative}) => {
	// TODO
	return <tr>
		<td></td>
		{comparative.map(item => {
			return <td>
				<div className={`table__cell ${item.highlighted ? 'table__cell__highlight--bottom': ''}`}>
					{item.buttons.map(button => button)}
				</div>
			</td>
		})}
	</tr>

}

export const ComparativeCompetitors = ({
	title,
	comparative,
	tecalis,
	competitor,
	expertButton,
	expertForm,
	tryButton,
	tryForm,
	lang,
}) => {
	const tryModalState = ModalState();
	const tryButtonObj = { text: tryButton, modalState: tryModalState };
	const showTryForm = tryForm ? true : false;

	const expertModalState = ModalState();
	const expertButtonObj = { text: expertButton, modalState: expertModalState };

	return (
		<>			
			<table className="table table--thin">
				{desktopComparativeHeader(tecalis, competitor)}
				{desktopComparativeBody(comparative, expertButtonObj, tryButtonObj, showTryForm)}
			</table>

			<ModalForm state={expertModalState} divFormId="expertForm" form={expertForm} />

			{tryForm && <ModalLogin state={tryModalState} lang={lang} selectedPlan="starter" plan={tryForm} />}
		</>
	);
};

const desktopComparativeHeader = (tecalis, competitor) => {
	return (
		<thead>
			<tr>
				<th>
					<div className="table__cell"></div>
				</th>
				<th>
					<div className="table__cell table__cell__highlight--top">
						<GatsbyImage image={getImage(tecalis.localFile)} alt={tecalis.alternativeText} />
					</div>
				</th>
				<th>
					<div className="table__cell">
						<h6>{competitor}</h6>
					</div>
				</th>
			</tr>
		</thead>
	);
};

const desktopComparativeBody = (comparative, expertButton, tryButton, showTryForm) => {
	return (
		<tbody>
			{comparative.map((row) => {
				return desktopComparativeRow(row);
			})}
			{desktopComparativeFooter(expertButton, tryButton, showTryForm)}
		</tbody>
	);
};

const desktopComparativeRow = (row) => {
	return (
		<tr>
			<td>
				<div className="table__cell">
					<b>{row.name}</b>
					<Tooltip tooltip={row.tooltip} />
				</div>
			</td>
			<td>
				<div className="table__cell table__cell__highlight--x">
					{/* {getIconText(row.text_tecalis, row.tecalis)} */}
				</div>
			</td>
			<td>
				{/* <div className="table__cell">{getIconText(row.text_competitor, row.competitor)}</div> */}
			</td>
		</tr>
	);
};

const desktopComparativeFooter = (expertButton, tryButton, showTryForm) => {
	return (
		<tr>
			<td></td>
			<td>
				<div className="table__cell table__cell__highlight--bottom">
					<button className="button button--lg" onClick={() => expertButton.modalState.openMenu()}>
						{expertButton.text}
					</button>
					{showTryForm && <a onClick={() => tryButton.modalState.openMenu()}>{tryButton.text}</a>}
				</div>
			</td>
			<td></td>
		</tr>
	);
};

const IconComponent = ({text, bool, onlyIcon = false}) => {
	if (!text && !bool) {
		return <i className="icon-close" />;
	}
	if (onlyIcon) {
		return <i className="icon-check" />
	}
	return text ? text : <i className="icon-check" />
};
