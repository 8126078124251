export const mountAvatarObject = (
	name,
	department = undefined,
	image = undefined,
	linkedin = undefined,
	mail = undefined,
	phone = undefined,
	phonePrefix = undefined,
	whatsapp = undefined,
	whatsappPrefix = undefined,
) => {
	return {
		name,
		department,
		image,
		linkedin,
		mail,
		phone,
		phonePrefix,
		whatsapp,
		whatsappPrefix,
	};
};
