import { Modal } from "antd"
import React, { useState } from "react"
import { initializeHubspotForm } from "../../utils/gatsby-helpers"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

// The state is the object of modal state
// The form is the hubspot data
export const ModalForm = ({ state, form, divFormId = "modal-form" }) => {
  const closeModalForm = () => {
    state.closeMenu()
  }

  initializeHubspotForm(
    form.portal_id,
    form.form_id,
    `#${divFormId}`,
    form.region
  )

  const title = (
    (form.title || form.description) &&
    <>
        {form.title && 
      <h4>{form.title}</h4>
        }
        {form.description && 
        <ReactMarkdown
            children={form.description}
            rehypePlugins={[rehypeRaw]}
          />
        }
    </>
  )

  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      onCancel={closeModalForm}
      title={title}
      visible={state.isOpen}
      width={"100%"}
      wrapClassName="main__section"
    >
        <div className="button-collapse-group">
          <div id={divFormId} className="form-hb" />
        </div>
    </Modal>
  )
}

// The modal state to manage if its open or closed and its functions
export const ModalState = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openMenu = () => setIsOpen(true)
  const closeMenu = () => setIsOpen(false)
  const toggleMenu = () => setIsOpen(!isOpen)

  return { isOpen, openMenu, closeMenu, toggleMenu }
}
