import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const ItemGroup = ({ items, hasImage = false, unboxed = false, ghost = false, firstOpen = false }) => {
    const [openItem, setOpenItem] = useState()
    React.useEffect(() => {
        if (firstOpen) {
            setOpenItem(items[0])
        }
        
    }, [])
    const toggleItem = (item) => {
        item?.id !== openItem?.id ? setOpenItem(item) : setOpenItem()
    }

    let groupClassName = 'item-group'
    if (!hasImage) {
        groupClassName += ' item-group--without-image'
    }
    let itemClassName = 'item'
    if (unboxed) {
        itemClassName += ' item--unboxed'
    } else if (ghost) {
        itemClassName += ' item--ghost'
    }
    return (
        <div className={groupClassName}>
            {hasImage && openItem?.icon &&
                <div className="item-group__image hidden-lg">
                    <GatsbyImage
                        alt={openItem?.icon.alternativeText}
                        image={getImage(openItem?.icon.localFile)}
                        placeholder="blurred"
                        quality={100}
                    />
                </div>
            }
            <div className="item-group__items">
                {
                    items.map((item, index) => {
                        return (
                            <div className={`${itemClassName} ${item.id === openItem?.id ? "item--active" : ""}`} onClick={() => toggleItem(item)} key={index}>
                                <div className="item__header">
                                    {unboxed &&
                                        <>
                                            <i className="icon-check-circle"></i>
                                            <h5>{item.title}</h5>
                                        </>}
                                    {!unboxed &&
                                        <>
                                            <div className="item__header__title">{item.title}</div>
                                            <i className="icon-chevron-down"></i>
                                        </>
                                    }
                                </div>
                                <div className="item__body">
                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    {hasImage &&
                                        <div className="item__body__image show-lg">
                                            <GatsbyImage
                                                alt={openItem?.icon.alternativeText}
                                                image={getImage(openItem?.icon.localFile)}
                                                placeholder="blurred"
                                                quality={100}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ItemGroup