import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import MainSection100 from "../components/Layout/main-section-100";
import MainSection50 from "../components/Layout/main-section-50";
import NewLayout from "../components/new_layout";
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk";
import CardPost from "../components/v2023/UI/Card/card-post";
import ItemGroup from "../components/v2023/UI/Item/item-group";
import { Comparative } from "../components/v2024/comparative";
import ImageGroup from "../components/v2024/image/image-group";
import ListHighlight from "../components/v2024/list/list-highlight";
import ModalLogin from "../components/v2024/Login/modal-login";
import MenuModalState from "../components/v2024/Login/modal-login-state";
import { ModalForm } from "../components/v2024/modal";
import { WhiteTileNb } from "../components/v2024/tile/tile";
import { mountAvatarObject } from "../utils/avatar-utils";
import { formatDate } from "../utils/date-utils";

const ComparativeCompetitor = ({ data, pageContext }) => {
	const lang = pageContext.langKey;
	const images = pageContext.images;
	const comparativeCompetitor = data.comparativeCompetitor;

	const signAvailable = comparativeCompetitor.comparative_electronic_signature_button ? true : false;
	const verificationAvailable = comparativeCompetitor.comparative_identity_verification_button ? true : false;

	const [showComparative, setShowComparative] = useState(signAvailable ? 'sign' : 'verification');

	const isSignActive = () => {
		if (showComparative === 'sign') {
			return true;
		}
		return false;
	};

	const isVerificationActive = () => {
		if (showComparative === 'verification') {
			return true;
		}
		return false;
	};

	const loginModalStatus = MenuModalState();

	const signatureOpinion = mountAvatarObject(
		comparativeCompetitor.opinions_signature[0]?.name,
		comparativeCompetitor.opinions_signature[0]?.job_position,
		comparativeCompetitor.opinions_signature[0]?.photo,
	);
	const verificationOpinion = mountAvatarObject(
		comparativeCompetitor.opinions_verifications[0]?.name,
		comparativeCompetitor.opinions_verifications[0]?.job_position,
		comparativeCompetitor.opinions_verifications[0]?.photo,
	);

	const pathBlog = lang === 'en' ? '/blog' : `/${lang}/blog`;
	const pathWhitePapers = lang === 'en' ? '/ebooks-whitepapers' : `/${lang}/ebooks-whitepapers`;
	const pathWebinars = lang === 'en' ? '/webinars' : '/es/webinars';
	const pathGeneric = lang === 'en' ? '/' : '/es/';

	const normalizeData = (item) => {
		if (item.strapi_component === 'sector.blog') {
			return {
				title: item.title,
				url: `${pathBlog}/${item.blog_post.url}`,
				tag: 'Blog',
				image: { localFile: images[item.image.localFile___NODE].data.image },
				date: item.blog_post.updated_at,
			};
		}

		if (item.strapi_component === 'sector.e-book') {
			return {
				title: item.title,
				url: `${pathWhitePapers}/${item.ebook.url}`,
				tag: 'eBook',
				image: { localFile: images[item.image.localFile___NODE].data.image },
				date: item.ebook.updated_at,
			};
		}

		if (item.strapi_component === 'sector.webinars') {
			return {
				title: item.title,
				url: `${pathWebinars}/${item.webinar.url}`,
				tag: 'Webinar',
				image: { localFile: images[item.image.localFile___NODE].data.image },
				date: item.webinar.updated_at,
			};
		}

		return {
			title: item.title,
			url: `${pathGeneric}${item.generic_landing_page.url}`,
			tag: lang === 'en' ? 'Use Case' : 'Caso de uso',
			image: { localFile: images[item.image.localFile___NODE].data.image },
			date: item.generic_landing_page.updated_at,
		};
	};

	const normalizeComparativeData = (tecalisName, competitorName, comparative, buttons) => {
		// Array of objects
		const data = [];
		// Characteristic list from tecalis
		const tecalisCharacteristicsList = [];
		// Characteristic list from competitor
		const competitorCharacteristicsList = [];

		// Mounting the characteristics list
		comparative.forEach((item) => {
			tecalisCharacteristicsList.push({ name: item.name, text: item.text_tecalis, bool: item.tecalis });
			competitorCharacteristicsList.push({ name: item.name, text: item.text_competitor, bool: item.competitor });
		});

		// Mounting the final object
		data.push({
			name: tecalisName,
			highlighted: true,
			characteristicsList: { characteristics: tecalisCharacteristicsList },
            buttons: buttons,
		});
		data.push({
			name: competitorName,
			highlighted: false,
			characteristicsList: { characteristics: competitorCharacteristicsList },
            buttons: []
		});
		return data;
	};

	// Talk expert button
    const talkExpertModalState = MenuModalState()
    const talkExpertButton = <button className="button button--lg" onClick={() => talkExpertModalState.openMenu()}>{comparativeCompetitor.comparative_talk_expert_button}</button>
	// Try button
    const tryModalState = MenuModalState()
    const tryButton = <a onClick={() => tryModalState.openMenu()}>{comparativeCompetitor.comparative_try_button}</a>

	const signElements = comparativeCompetitor.comparative_electronic_signature_elements;
	const signComparative = normalizeComparativeData(
		comparativeCompetitor.logo_tecalis_comparative,
		comparativeCompetitor.competitor_comparative,
		signElements,
        [talkExpertButton, tryButton]
	);

	const identityVerificationElements = comparativeCompetitor.comparative_identity_verification_elements
	const identityVerifcationComparative = normalizeComparativeData(
		comparativeCompetitor.logo_tecalis_comparative,
		comparativeCompetitor.competitor_comparative,
		identityVerificationElements,
		[talkExpertButton]
	)
	return (
		<NewLayout noHeader pageContext={pageContext}>
			{/* HERO */}
			<MainSection50
				sectionType="color"
				sectionColor="purple-dark"
				sectionSize="lg"
				childrenLeft={
					<>
						<GatsbyImage
							alt={comparativeCompetitor.logo_tecalis.alternativeText}
							image={getImage(comparativeCompetitor.logo_tecalis.localFile)}
						/>
						<h1 className="merriweather">{comparativeCompetitor.hero_title}</h1>
						<div className="container__text">
							<ReactMarkdown
								children={comparativeCompetitor.hero_description}
								rehypePlugins={[rehypeRaw]}
							/>
						</div>
						<div className="button__wrapper">
							<a className="button button--xl button--ghost" href="#ready-to-talk">
								{comparativeCompetitor.hero_button_demo}
							</a>
							{comparativeCompetitor.hero_button_try && showComparative !== 'verification' && (
								<>
									<button
										className="button button--xl button--secondary--ghost button--secondary"
										onClick={() => loginModalStatus.openMenu()}
									>
										{comparativeCompetitor.hero_button_try}
									</button>
									<ModalLogin
										lang={lang}
										state={loginModalStatus}
										plan={comparativeCompetitor.try_form}
										selectedPlan="starter"
									/>
								</>
							)}
						</div>
					</>
				}
				childrenRight={
					<div data-aos="fade-left">
						<GatsbyImage
							alt={comparativeCompetitor.hero_image.alternativeText}
							image={getImage(comparativeCompetitor.hero_image.localFile)}
						/>
					</div>
				}
			/>

			{signAvailable && verificationAvailable && (
				<MainSection100 borderBottom>
					<div className="container__text">
						<ReactMarkdown
							children={comparativeCompetitor.comparative_description}
							rehypePlugins={[rehypeRaw]}
						/>
					</div>
					{/* Type selector */}
					<div className="tabs tabs--pills-big">
						<div
							className={`tabs__tab ${showComparative === 'sign' ? 'tabs__tab--active' : ''}`}
							onClick={() => setShowComparative('sign')}
						>
							{' '}
							{comparativeCompetitor.comparative_electronic_signature_button}
						</div>
						<div
							className={`tabs__tab ${showComparative === 'verification' ? 'tabs__tab--active' : ''}`}
							onClick={() => setShowComparative('verification')}
						>
							{' '}
							{comparativeCompetitor.comparative_identity_verification_button}
						</div>
					</div>
				</MainSection100>
			)}

			{/* Comparative */}
			<MainSection100>
				{isSignActive() && (
					<Comparative
						comparative={signComparative}
						modals={
							<>
								<ModalForm
									state={talkExpertModalState}
									divFormId="expertFormSign"
									form={comparativeCompetitor.talk_expert_form}
								/>
								<ModalLogin
									state={tryModalState}
									lang={lang}
									selectedPlan="starter"
									plan={comparativeCompetitor.try_form}
								/>
							</>
						}
					/>
				)}
				{isVerificationActive() && (
					<Comparative
						comparative={identityVerifcationComparative}
						modals={
							<ModalForm
								state={talkExpertModalState}
								divFormId="expertFormTry"
								form={comparativeCompetitor.talk_expert_form}
							/>
						}
					/>
				)}
			</MainSection100>

			{/* Trust */}
			<MainSection100>
				<h2>{comparativeCompetitor.trust_title}</h2>
				<div className="grid-md">
					{/* Sign logos */}
					{isSignActive() &&
						comparativeCompetitor.trust_logos_signature.map((logo, index) => {
							return <GatsbyImage key={index} image={getImage(logo.image.localFile)} alt={logo.alt} />;
						})}
					{/* Verification logos */}
					{isVerificationActive() &&
						comparativeCompetitor.trust_logos_verification.map((logo, index) => {
							return <GatsbyImage key={index} image={getImage(logo.image.localFile)} alt={logo.alt} />;
						})}
				</div>
			</MainSection100>

			{/* Opinion */}
			<MainSection100>
				{/* Waiting to add the avatar, because the component modification I need is at the WEB-67 branch */}
				{isSignActive() && (
					<WhiteTileNb body={comparativeCompetitor.opinions_signature[0].message} avatar={signatureOpinion} />
				)}
				{isVerificationActive() && (
					<WhiteTileNb
						body={comparativeCompetitor.opinions_verifications[0].message}
						avatar={verificationOpinion}
					/>
				)}
			</MainSection100>

			{/* Image comparative */}
			<MainSection100>
				<h2>{comparativeCompetitor.comparative_description_title}</h2>
				<div className="grid-lg-3">
					{isSignActive() &&
						comparativeCompetitor.comparative_description_explanations_signature.map((item, index) => {
							return <ImageGroup key={index} basicInfoWithImage={item} />;
						})}
					{isVerificationActive() &&
						comparativeCompetitor.comparative_description_explanations_verification.map((item, index) => {
							return <ImageGroup key={index} basicInfoWithImage={item} />;
						})}
				</div>
			</MainSection100>

			{/* Why Tecalis */}
			<MainSection100 sectionType="color" sectionColor="gray gray--noframe">
				<h2>{comparativeCompetitor.why_tecalis_title}</h2>
				<div className="grid-lg-4">
					{isSignActive() && (
						<ListHighlight items={comparativeCompetitor.why_tecalis_explanation_signature} />
					)}
					{isVerificationActive() && (
						<ListHighlight items={comparativeCompetitor.why_tecalis_explanation_verification} />
					)}
				</div>
			</MainSection100>

			{/* FaQ */}
			<MainSection100 containerSlim>
				<h3>{comparativeCompetitor.faq_title}</h3>
				{isSignActive() && <ItemGroup items={comparativeCompetitor.faq_signature_items} firstOpen unboxed />}
				{isVerificationActive() && (
					<ItemGroup items={comparativeCompetitor.faq_verification_items} firstOpen unboxed />
				)}
			</MainSection100>

			{/* Last News */}
			<MainSection100>
				<h2>{comparativeCompetitor.news_title}</h2>
				<div className="grid-lg-3-h">
					{isSignActive() &&
						comparativeCompetitor.news_item_signature.map((item, index) => {
							const aux = normalizeData(item);
							aux.date = formatDate(aux.date);
							return (
								<CardPost
									key={index}
									lang={lang}
									tag={aux.tag}
									url={aux.url}
									name={aux.title}
									image={aux.image}
									showMore
									date={aux.date}
								/>
							);
						})}
					{isVerificationActive() &&
						comparativeCompetitor.news_item_verification.map((item, index) => {
							const aux = normalizeData(item);
							aux.date = formatDate(aux.date);
							return (
								<CardPost
									key={index}
									lang={lang}
									tag={aux.tag}
									url={aux.url}
									name={aux.title}
									image={aux.image}
									showMore
									date={aux.date}
								/>
							);
						})}
				</div>
			</MainSection100>

			{/* comment */}
			<MainSection100 containerSlim>
				<p className="text--gray-dark">{comparativeCompetitor.info}</p>
			</MainSection100>

			<ReadyToTalk lang={lang} />
		</NewLayout>
	);
};

export default ComparativeCompetitor

export const comparativeCompetitorQuery = graphql`
    query ($id: String!, $langKey: String!)  {
        comparativeCompetitor: strapiComparativeCompetitors (locale: {eq: $langKey}, id: {eq: $id}){
            seo {
                title
                meta_description
            }
            logo_tecalis {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 146
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            hero_title
            hero_description
            hero_button_demo
            hero_button_try
            hero_image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 640
                            placeholder: BLURRED
                            formats: [WEBP]
                            breakpoints: [496, 592]
                        )
                    }
                }
            }
            comparative_description
            comparative_electronic_signature_button
            comparative_identity_verification_button
            logo_tecalis_comparative {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 130
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            competitor_comparative
            comparative_electronic_signature_elements {
                name
                tecalis
                text_tecalis
                competitor
                text_competitor
            }
            comparative_identity_verification_elements {
                name
                tecalis
                text_tecalis
                competitor
                text_competitor
            }
            comparative_talk_expert_button
            talk_expert_form {
				title
				description
                region
                portal_id
                form_id
            }
            try_form {
                form_email
                or_sign_up
                login_text
                form_name
                form_tc_description
                form_repassword
                form_password
                title
                description
                button_google
                button_email
                form_lastname
                signup_text
                button_signup
                button_login
                remember_me
                forgot_password
                title_login
                description_login
                form_tc_info
            }
            comparative_try_button
            trust_title
            trust_logos_signature {
                alt
                image {
                    name
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 120
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            trust_logos_verification {
                alt
                image {
                    name
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 120
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            opinions_signature {
                name
                job_position
                photo {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 64
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                message
            }
            opinions_verifications {
                name
                job_position
                photo {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 64
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
                message
            }
            comparative_description_title
            comparative_description_explanations_signature {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 704
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [352, 432, 444, 512]
                            )
                        }
                    }
                }
            }
            comparative_description_explanations_verification {
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 704
                                placeholder: BLURRED
                                formats: [WEBP]
                                breakpoints: [352, 432, 444, 512]
                            )
                        }
                    }
                }
            }
            why_tecalis_title
            why_tecalis_explanation_signature {
                order
                title
                description
            }
            why_tecalis_explanation_verification {
                order
                title
                description
            }
            faq_title
            faq_signature_items {
                id
                title
                description
            }
            faq_verification_items {
                id
                title
                description
            }
            news_title
            news_item_signature
            news_item_verification
            info
        }
    }
`