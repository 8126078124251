import React from "react";

export const Tooltip = ({tooltip}) => {
    return (
    <>
        {tooltip && <span 
            className="tooltip-icon" 
            data-tooltip={tooltip}>
            <i className="icon-info"></i>
        </span>}
    </>
    )
}